import * as React from "react";
import Layout from "../components/layout";
import SEO from "../components/seo";
import { Carousel } from "react-bootstrap";
import { StaticImage } from "gatsby-plugin-image";
import { Link } from "gatsby";

const Concrete = () => (
  <Layout>
    <SEO
      title="Earthworks"
      image={"/earthworks.jpg"}
      description="We provide reliable earthworks and excavation solutions, servicing both residential and commercial projects. "
    />

    <div className="row g-0">
      <div className="col-lg-6 d-flex align-items-center">
        <div className="content-box">
          <h1 className="section-heading section-heading--left">Earthworks</h1>

          <p>
            We provide reliable earthworks and excavation solutions, servicing
            both residential and commercial projects.
          </p>
          <p>
            Our team of specialist operators have extensive knowledge and
            experience working on challenging earthworks sites. We own and
            operate an extensive amount of plant and equipment resources that
            allow us to allocate appropriate machinery to achieve a quality
            finish for your project.
          </p>
          <ul>
            <li>Bulk earthworks</li>
            <li>Cut to fill</li>
            <li>Cut to waste</li>
            <li>Cartage</li>
            <li>Vegetation removal</li>
            <li>Trimming, cutting, battering and shoring</li>
            <li>Trenching</li>
            <li>Excavation and augering</li>
            <li>Site clearing</li>
            <li>Foundations and slab preparation</li>
          </ul>
          <p>
            <Link className="btn btn-primary mt-4" to="/contact/">
              Free quote
            </Link>
          </p>
        </div>
      </div>
      <div className="col-lg-6 d-flex align-items-center justify-content-center">
        {/* <Carousel>
              <Carousel.Item>
                <StaticImage
                  aspectRatio={16 / 10}
                  src="../images/concrete.jpg"
                  alt="concrete path"
                  loading="eager"
                />
              </Carousel.Item>
            </Carousel> */}
        <StaticImage
          aspectRatio={16 / 13}
          transformOptions={{ cropFocus: "center" }}
          src="../images/earthworks.jpg"
          alt="Digger scooping dirt into truck"
          loading="eager"
        />
      </div>
    </div>
  </Layout>
);

export default Concrete;
